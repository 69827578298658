import { useForm, useFormState } from 'react-final-form';
import { useEffect, useRef, useState } from 'react';

export const useWithNumberField = (fieldNameFrom, filedNameTo, hide) => {
  const { change, submit } = useForm();
  const { values } = useFormState();

  const fromRef = useRef(null);

  const [from, setFrom] = useState(values[fieldNameFrom] || '');
  const [to, setTo] = useState(values[filedNameTo] || '');

  const onSubmit = () => {
    change(fieldNameFrom, from);
    change(filedNameTo, to);
    submit();
    hide();
  };

  useEffect(() => {
    fromRef.current?.focus();
  }, []);

  return { from, setFrom, to, setTo, onSubmit, fromRef, values };
};
