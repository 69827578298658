import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NewChips from '../common/NewChips';
import FilterModal from '../common/FilterModal';
import { BUILDING_TYPE_ID } from '../../../utils/helpers/constants';

export const ModalsWithFilters = forwardRef(
  ({ values, change, submit, hasCategory }, ref) => {
    const { t } = useTranslation();
    const [modalConfig, setModalConfig] = useState();
    const categoryFlat = [
      BUILDING_TYPE_ID.FLAT_ALL,
      BUILDING_TYPE_ID.FLAT_NEW,
      BUILDING_TYPE_ID.FLAT_SECONDARY
    ].includes(values.category_id);
    const categoryHouse = values.category_id === BUILDING_TYPE_ID.HOUSE;
    const categoryOffice = values.category_id === BUILDING_TYPE_ID.OFFICE;

    const hideModal = () => setModalConfig(null);

    useImperativeHandle(
      ref,
      () => {
        return { getModalConfig: () => modalConfig };
      },
      [modalConfig]
    );

    const onlyPriceTo = values.price_to
      ? t('search.search_title_service.to', {
          to: values.price_to,
          unit: 'AZN'
        })
      : t('new_search.price');
    const onlyPriceFrom = values.price_from
      ? t('search.search_title_service.from', {
          from: values.price_from,
          unit: 'AZN'
        })
      : onlyPriceTo;
    const price =
      values.price_from && values.price_to
        ? `${values.price_from} - ${values.price_to} AZN`
        : onlyPriceFrom;

    const roomsCountTitle = values.room_ids?.length
      ? t('new_search.roominess', { count: values.room_ids.join(', ') })
      : t('new_search.rooms');

    const metres = t('search.search_title_service.units.metres');
    const acres = t('search.search_title_service.units.acres');
    const isLand = values.category_id === '9';
    const unit = isLand ? acres : metres;
    const onlyAreaTo = values.area_to
      ? t('search.search_title_service.to', { to: values.area_to, unit })
      : t('new_search.area');
    const onlyAreaFrom = values.area_from
      ? t('search.search_title_service.from', { from: values.area_from, unit })
      : onlyAreaTo;
    const area =
      values.area_from && values.area_to
        ? `${values.area_from} - ${values.area_to} ${unit}`
        : onlyAreaFrom;

    const secondaryOrEmpty =
      values.category_id === '3'
        ? t('new_search.secondary_building')
        : t('search.form.building_type_group.title');
    const buildingType =
      values.category_id === '2'
        ? t('new_search.new_building')
        : secondaryOrEmpty;

    return (
      <div
        className="new-search__chips-wrapper"
        style={hasCategory ? { marginTop: '10px' } : {}}
      >
        <NewChips
          type="button"
          text={price}
          dirty={values.price_from || values.price_to}
          resetHandler={() => {
            change('price_from', '');
            change('price_to', '');
            submit();
          }}
          stat="new-search-price-modal"
          onClick={() => setModalConfig('price')}
        />

        <NewChips
          type="button"
          text={area}
          dirty={values.area_from || values.area_to}
          stat="new-search-area-modal"
          resetHandler={() => {
            change('area_from', '');
            change('area_to', '');
            submit();
          }}
          onClick={() => setModalConfig('area')}
        />

        {(categoryFlat || categoryHouse || categoryOffice) && (
          <NewChips
            type="button"
            text={roomsCountTitle}
            dirty={!!values.room_ids}
            stat="new-search-rooms-count-modal"
            resetHandler={() => {
              change('room_ids');
              submit();
            }}
            onClick={() => setModalConfig('roomsCount')}
          />
        )}

        {categoryFlat && (
          <NewChips
            type="button"
            dirty={values.category_id === '2' || values.category_id === '3'}
            resetHandler={() => {
              change('category_id', '1');
              submit();
            }}
            text={buildingType}
            stat="new-search-building-modal"
            onClick={() => setModalConfig('buildingType')}
          />
        )}

        <FilterModal
          hide={hideModal}
          isOpen={!!modalConfig}
          modalConfig={modalConfig}
        />
      </div>
    );
  }
);

ModalsWithFilters.displayName = 'ModalsWithFilters';

ModalsWithFilters.propTypes = {
  sort: PropTypes.string,
  change: PropTypes.func,
  submit: PropTypes.func,
  values: PropTypes.object,
  hasCategory: PropTypes.bool
};
