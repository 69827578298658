export const BUILDING_TYPE_ID = {
  FLAT_ALL: '1',
  FLAT_NEW: '2',
  FLAT_SECONDARY: '3',
  HOUSE: '5',
  OFFICE: '7',
  GARAGE: '8',
  AREA: '9',
  OBJECT: '10'
};
