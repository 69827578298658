import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Form, FormSpy } from 'react-final-form';
import { withApollo } from '@apollo/client/react/hoc';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { NewSearchBar } from './NewSearchBar';
import NewSearchCategories from './NewSearchCategories';
import {
  convertSearchValues,
  convertValues,
  fromObjToUrl
} from '../../utils/helpers';
import {
  setChosenLocationNames,
  setCurrentCity,
  setCurrentState,
  setLocationIds,
  setResetButtonIsDisabled
} from '../../actions';
import { getLocalDataQuery, locationsQuery } from '../../queries';
import SelectTabs from './SelectTabs';
import SearchTypeHeader from './SelectTypeHeader';
import { ModalsWithFilters } from './ModalsWithFilters';
import useScrollState from '../../hooks/useScrollState';
import { useBridgeState } from '../../bridge';

const getInitialSearchType = (url, leased) => {
  switch (true) {
    case /#more/.test(url) || /^\/$/.test(url):
      return undefined;
    case /\/alqi-satqi/.test(url):
      return 'buy';
    case /\?paid_daily=true/.test(url):
      return 'paid_daily';
    case /\/kiraye/.test(url):
      return 'rent';
    case leased === 'true':
      return undefined;
    case leased && leased !== 'true':
      return 'buy';
    default:
      return undefined;
  }
};

const initialFormValues = {
  leased: 'false',
  categoryId: '1',
  cityId: '1'
};

const NewSearch = ({ client, totalAdsCount, formElToAssign, isLoading }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const formEl = useRef(null);
  const modalsWithFiltersRef = useRef();
  const storedFormState = useSelector(state => state.searchForm.currentState);
  const sortingValue = useSelector(state => state.searchForm.currentSortType);
  const currentCity = useSelector(state => state.searchForm.currentCity);
  const storedLocationIds = useSelector(state => state.searchForm.locationIds);
  const categoryTouched = storedFormState?.category_touched || false;
  const [searchType, setSearchType] = useState(
    getInitialSearchType(router.asPath)
  );
  const { isWebView } = useBridgeState();
  const isScroll = useScrollState();

  const allClasses = classNames(
    'new-search-container',
    isScroll && !isWebView && 'scrolling'
  );

  useEffect(() => {
    setSearchType(getInitialSearchType(router.asPath, storedFormState?.leased));
  }, [router.pathname, router.asPath]);

  const handleReset = () => {
    const resetData = {
      city_id: '1',
      leased: 'false',
      category_id: '1',
      locations_ids: undefined,
      category_touched: false
    };
    formEl.current.reset(resetData);
    dispatch(setChosenLocationNames([]));
    dispatch(setLocationIds([]));
    dispatch(setCurrentCity('1'));
    dispatch(setCurrentState(resetData));
    setSearchType(null);
    router.replace('/');
  };

  const onSubmit = async values => {
    const { url, ...queryObj } = router.query;
    dispatch(setCurrentState(values));

    await client
      .query({
        query: getLocalDataQuery(values.city_id),
        variables: {
          id: values.city_id
        },
        fetchPolicy: 'cache-only'
      })
      .then(localData => {
        const { href, as } = fromObjToUrl(
          values,
          localData.data,
          queryObj.sorting
        );

        router.push(href, as);
      });
  };

  const changeFormHandler = ({ values }) => {
    const isEqual =
      JSON.stringify(
        convertSearchValues({
          city_id: '0',
          leased: 'false',
          category_id: '1'
        })
      ) === JSON.stringify(convertValues(values));

    setTimeout(() => dispatch(setResetButtonIsDisabled(isEqual)), 0);
  };

  const locationsQueryVariables = {
    scope: 'ALL',
    limit: 1000
  };

  const { data } = useQuery(locationsQuery(locationsQueryVariables), {
    variables: locationsQueryVariables
  });
  const locations = data?.locations || [];

  useEffect(() => {
    dispatch(
      setChosenLocationNames(
        locations
          .filter(location => storedLocationIds.includes(location.id))
          .map(location => location.name.trim())
      )
    );
  }, [locations]);

  return (
    <div className={allClasses}>
      <Form
        onSubmit={onSubmit}
        subscription={{
          submitting: true,
          values: true
        }}
        initialValues={{
          city_id: currentCity || initialFormValues.cityId || '0',
          leased: initialFormValues.leased || String(searchType !== 'buy'),
          category_id: initialFormValues.categoryId || '1',
          room_ids: initialFormValues.roomIds,
          price_from: initialFormValues.priceFrom,
          price_to: initialFormValues.priceTo,
          area_from: initialFormValues.areaFrom,
          area_to: initialFormValues.areaTo,
          land_area_from: initialFormValues.landAreaFrom,
          land_area_to: initialFormValues.landAreaTo,
          paid_daily:
            initialFormValues.paidDaily || String(searchType === 'paid_daily'),
          has_bill_of_sale: initialFormValues.hasBillOfSale,
          only_residences: initialFormValues.onlyResidences,
          has_mortgage: initialFormValues.hasMortgage,
          has_repair: initialFormValues.hasRepair,
          floor_from: initialFormValues.floorFrom,
          floor_to: initialFormValues.floorTo,
          floor_first: initialFormValues.floorFirst?.toString(),
          floor_last: initialFormValues.floorLast?.toString(),
          location_ids: storedLocationIds || initialFormValues.locationIds,
          building_type: initialFormValues.buildingType,
          sorting: sortingValue,
          ...storedFormState
        }}
      >
        {renderProps => {
          const { values } = renderProps;
          formEl.current = renderProps.form;
          // eslint-disable-next-line
          (formElToAssign || {}).current = renderProps.form;
          const searchVariables = {
            filter: convertSearchValues(values, false, sortingValue)
          };

          return (
            <form
              method="get"
              id="new_search"
              acceptCharset="UTF-8"
              noValidate="novalidate"
              className="search-bar__form"
              onSubmit={renderProps.handleSubmit}
            >
              <FormSpy
                onChange={changeFormHandler}
                subscription={{ values: true }}
              />
              {searchType && (
                <SearchTypeHeader
                  values={values}
                  searchType={searchType}
                  handleBack={handleReset}
                  totalCount={totalAdsCount}
                  hasCategory={categoryTouched}
                  isLoading={isLoading}
                />
              )}
              {!searchType && (
                <SelectTabs
                  values={values}
                  change={renderProps.form.change}
                  submit={renderProps.form.submit}
                />
              )}
              <NewSearchBar
                modalWithFilters={modalsWithFiltersRef.current?.getModalConfig()}
                formEl={formEl}
                searchVariables={searchVariables}
                initialFormValues={initialFormValues}
                extraPadding={!categoryTouched && searchType}
              />
              {!categoryTouched && (
                <NewSearchCategories
                  values={values}
                  change={renderProps.form.change}
                  submit={renderProps.form.submit}
                />
              )}
              {searchType && (
                <ModalsWithFilters
                  ref={modalsWithFiltersRef}
                  values={values}
                  hasCategory={categoryTouched}
                  change={renderProps.form.change}
                  submit={renderProps.form.submit}
                />
              )}
            </form>
          );
        }}
      </Form>
    </div>
  );
};

NewSearch.propTypes = {
  client: PropTypes.object,
  totalAdsCount: PropTypes.number,
  formElToAssign: PropTypes.object,
  isLoading: PropTypes.bool
};

NewSearch.defaultProps = {
  totalAdsCount: 0
};

export default withApollo(NewSearch);
