import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../../Icon';
import CloseIcon from '../../../../public/static/images/new_search/icons/icon-close.svg';

const NewChips = ({
  id,
  icon,
  text,
  type,
  name,
  stat,
  value,
  style,
  dirty,
  onClick,
  iconSize,
  storedValue,
  borderRadius,
  resetHandler,
  cypress,
  color,
  iconActiveColor,
  iconNeedColorChange,
  activeIcon
}) => {
  const active =
    type === 'checkbox'
      ? storedValue?.includes(value)
      : // eslint-disable-next-line
        value !== undefined && value === storedValue;

  const iconColor =
    iconActiveColor && active ? iconActiveColor : color || '#fff';
  return (
    <label
      id={id}
      className={`chips-container${active ? ' chips-active' : ''}`}
      style={{ borderRadius, ...style }}
    >
      <div
        tabIndex={0}
        role="button"
        data-stat={stat}
        onClick={onClick}
        onKeyPress={onClick}
        className={classNames({
          chips__box: icon
        })}
      >
        {type !== 'button' && (
          <Field
            name={name}
            component="input"
            type={type}
            className="display-none"
            value={value === undefined ? '' : value}
          />
        )}
        {icon && (
          <Icon
            IconComponent={active && activeIcon ? activeIcon : icon}
            width={iconSize}
            height={iconSize}
            color={iconColor}
            iconNeedColorChange={iconNeedColorChange}
          />
        )}
        <span className="chips__text" data-cy={cypress}>
          {text}
        </span>
      </div>
      {resetHandler && dirty && (
        <div
          tabIndex={0}
          role="button"
          onClick={resetHandler}
          onKeyPress={resetHandler}
          className="chips__reset"
        >
          <Icon
            width={11}
            height={11}
            indent={false}
            IconComponent={CloseIcon}
          />
        </div>
      )}
    </label>
  );
};

NewChips.propTypes = {
  icon: PropTypes.func,
  id: PropTypes.string,
  cypress: PropTypes.string,
  stat: PropTypes.string,
  text: PropTypes.string,
  dirty: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  name: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  iconSize: PropTypes.number,
  borderRadius: PropTypes.string,
  resetHandler: PropTypes.func,
  type: PropTypes.oneOf(['button', 'radio', 'checkbox']),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.array
  ]),
  storedValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.array
  ]),
  color: PropTypes.string,
  iconActiveColor: PropTypes.string,
  iconNeedColorChange: PropTypes.bool,
  activeIcon: PropTypes.func
};

NewChips.defaultProps = {
  borderRadius: '100px',
  iconSize: 20
};

export default NewChips;
